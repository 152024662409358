import React from "react"
import { SectionTitle } from "../../components/styled"
import JobContent from "../../components/JobContent"
import SearchJob from "../../components/SearchJob"
import { Seo } from "../../components/seo"
import useJobs from "../../hooks/useJobs"
import Contact from "../../components/Contact"
const JobsListingEs = () => {
  const dataJobs = useJobs("Spanish")
  
  return (
    <>
      <Seo
        title={`Página de Empleo【QUICK GLOBAL MEXICO】`}
        image={
          dataJobs.length !== 0 ? dataJobs[0].types[0].image.url : ""
        }
        description={`Un servicio de agente de cambio de empleo que publica información laboral en México y brinda apoyo para el empleo y el cambio de empleo. Apoyamos no sólo los cambios laborales dentro de México, sino también los cambios laborales desde Japón.`}
        author={`919mexico.com`}
        url={`https://www.919mexico.com/es/jobs-listing/`}
        language={"es"}
      />

      <section className="container jobs-listing english-page-body">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>
                Empleo de México, empleo e información sobre cambio de carrera
              </SectionTitle>
              {/* <hr className="u-line-bottom" />
              <div className="jobs-listing__search--box">
                <SearchJob lang="es" />
              </div> */}
            </div>
            <div className="jobs-listing__job-info">
              <hr className="u-line-bottom" />
              {dataJobs.map((jobs, i) => (
                <React.Fragment key={"jobContent_" + i}>
                  <JobContent jobs={jobs} lang={"es"} />
                </React.Fragment>
              ))}
              <Contact lang={"es"} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobsListingEs
